<template>
    <div class="app-container">
        <pre style="word-wrap: break-word; white-space: pre-wrap;">{{xml}}</pre>
    </div>
</template>
<script>
export default {
    data() {
        return {
            xml: '',
        }
    },
    created() {
        this.xml = this.$store.state.xml.content.uploadContent
    },
}
</script>
